<!-- eslint-disable vue/no-unused-vars -->
<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <progress-spinner :isLoading="loading" />
    <v-toolbar-title>{{this.appointmentDetails?.Name}}</v-toolbar-title>
    <br>
    <v-row>
      <v-col cols="12">
        <v-toolbar-title>Assigned</v-toolbar-title>
        <v-card>
          <v-data-table
            v-if="this.appointmentDetails?.Employees"
            :headers="assignedHeaders"
            :items="this.appointmentDetails?.Employees"
            sort-by="Name"
            item-key="Id"
            class="elevation-1 row-pointer"
          >
            <template v-slot:item.Action="{ item }">
              <div @click="handleRemoveWorker(item)" class="mdi mdi-close-circle text-h3 red--text"></div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-toolbar-title>Assignable</v-toolbar-title>
        <v-card>
          <v-data-table
            :headers="assignableHeaders"
            :items="this.assignable"
            :options.sync="assignableOptions"
            :server-items-length="totalAssignable"
            sort-by="Name"
            item-key="Id"
            class="elevation-1 row-pointer"
          >
            <template v-slot:item.Name="{ item }">
              {{`${item.FirstName} ${item.LastName}`}}
            </template>
            <template v-slot:item.Action="{ item }">
              <v-btn
                class="primary pa-0"
                @click="handleAssignEmployee(item)"
              >
                Assign
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <div class="py-3" />
  </v-container>
</template>

<script>
const axios = require("axios");
export default {
  name: "AppointmentType",
  data() {
    return {
      assignableOptions: {},
      totalAssignable: 10,
      loading: false,
      page: 1,
      pageSize: 10,
      assignedHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "Name",
        },
        {
          text: "Action",
          align: "end",
          sortable: false,
          value: "Action",
        }
      ],
      assignableHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "Name",
        },
        {
          text: "Assign to Appointment Type",
          align: "end",
          sortable: false,
          value: "Action",
        }
      ],
      appointmentTypesWithEmployees: [],
      appointmentTypes: [],
      appointmentDetails: null,
      assignable: []
    };
  },

  watch: {
    assignableOptions() {
      this.getEmployeesGroup();
    }
  },

  methods: {
    async assignEmployeeToAppointments(employeeId, appointmentTypeIds) {
      this.loading = true;
      try {
        let { data } = await axios.post(`employees/${employeeId}/appointment-types/assign-multiple`,
          {
            appointmentTypeIds
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
          }
        );
        return data;
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handleAssignEmployee(item) {
      this.employeeAssigneds = [];
      let employeeName = `${item.FirstName} ${item.LastName}`;
      this.employeeAssigneds.push(employeeName);
      this.employeeAssigneds.push(...this.appointmentDetails.assignedTo);
      await this.assignEmployeeToAppointments(item.Id, [this.appointmentDetails.Id]);
      this.updateAppointmentWorkers();
      this.assignable = this.assignable.filter((filteringItem) => employeeName !== `${filteringItem.FirstName} ${filteringItem.LastName}`);
    },

    async unassignEmployeeAppointment(employeeId, appointmentId) {
      this.loading = true;
      try {
        let { data } = await axios.post(`employees/${employeeId}/unassign-appointment-type/${appointmentId}`, null,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
          }
        );
        console.log(data);
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handleRemoveWorker (worker) {
      this.employeeAssigneds = this.appointmentDetails.assignedTo;
      this.employeeAssigneds = this.employeeAssigneds.filter((item) => item !== worker.Name);
      await this.unassignEmployeeAppointment(worker.Id, this.appointmentDetails.Id);
      await this.updateAppointmentWorkers();
      await this.getEmployeesGroup();
    },

    async getEmployeesGroup() {
      this.loading = true;
      this.assignable = [];

      let {page, itemsPerPage} = this.assignableOptions;
      itemsPerPage = itemsPerPage > 0 ? itemsPerPage : 1000;

      try {
        let { data } = await axios.get(`employees/acl?page=${page}&size=${itemsPerPage}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.assignable = data.rows.filter((assignable) => !this.appointmentDetails.Employees.find((item) => item.Id === assignable.Id));
        this.totalAssignable = data.count;

      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async getAppointmentTypes() {
      this.loading = true;
      this.appointmentTypes = [];
      try {
        let { data } = await axios.get(`appointments/types`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.appointmentTypes = data;
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async getAppointmentTypesWithEmployees(appointmentTypeIds) {
      this.loading = true;
      this.appointmentTypesWithEmployees = [];
      try {
        let { data } = await axios.post(`appointments/types/with-employees`, {appointmentTypeIds}, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        this.appointmentTypesWithEmployees = data;
        this.appointmentTypesWithEmployees.forEach((item) => item.assignedTo = item.Employees.map((item) => `${item.FirstName} ${item.LastName}`));
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async updateAppointmentWorkers() {
      await this.getAppointmentTypes();
      await this.getAppointmentTypesWithEmployees(this.appointmentTypes.map((item) => item.Id));
      let appointment = this.appointmentTypesWithEmployees.find((item) => this.arraysHaveSameItems(item.assignedTo, this.employeeAssigneds));
      if (appointment) {
        appointment.Employees.forEach((item) => item.Name = `${item.FirstName} ${item.LastName}`)
        this.appointmentDetails = appointment;
        window.localStorage.setItem('appointmentDetails', JSON.stringify(this.appointmentDetails));
      }
    },

    arraysHaveSameItems(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }

      arr1.sort();
      arr2.sort();

      return arr1.every((item, index) => item === arr2[index])
    }
  },
  async mounted() {
    this.appointmentDetails = this.$route.params;
    if (this.appointmentDetails?.Name) {
      window.localStorage.setItem('appointmentDetails', JSON.stringify(this.appointmentDetails));
    } else {
      this.appointmentDetails = JSON.parse(window.localStorage.getItem('appointmentDetails'));
    }
    if (!this.appointmentDetails?.Name) {
      this.$router.push({ name: 'Appointment Type' });
    }
    await this.getAppointmentTypes();
    await this.getAppointmentTypesWithEmployees(this.appointmentTypes.map((item) => item.Id));
    await this.getEmployeesGroup();
  }
};
</script>
<style>
tbody tr :hover {
  cursor: pointer;
}
</style>
